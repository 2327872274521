@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope/Manrope-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope/Manrope-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope/Manrope-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope/Manrope-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope/Manrope-ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
}

.text-default {
  color: var(--color-primary);
}

.text-default-light {
  color: var(--color-primary-light);
}

.text-md {
  font-size: 1.5rem;
}