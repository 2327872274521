@import 'antd/dist/reset.css';
//@import "normalize";
@import 'variables';
@import 'typography';
@import 'nprogress';
@import 'antd';
@import 'MUITheme';

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: Manrope, sans-serif !important;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth !important;
}

html {
  font-size: 10px;
  text-size-adjust: 100%;
}

.tablePageWrapper {
  padding: 0 32px 32px;
}

.actionMenuIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  transition: all ease 0.3s;
  color: var(--table-heading-color);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    transition: all ease 0.3s;
    cursor: pointer;
  }

  svg {
    font-size: 1.8rem !important;
  }
}

/** Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgb(0 0 0 / 5%);
  box-shadow: inset -0.5px 0 0 rgb(0 0 0 / 7%), inset -1px 0 0 rgb(0 0 0 / 2%),
    inset 1px 0 0 rgb(0 0 0 / 2%), inset 0.5px 0 0 rgb(0 0 0 / 7%);
}

::-webkit-scrollbar-thumb {
  background: darken(#a9a9a9, 1%);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darken(#a9a9a9, 10%);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--text-light-color) transparent;
}
